<template>
    <v-card rounded="lg" class="pa-3">
        <v-card-text>
            <h5 class="text-center mb-0">{{ $t('clientOpinion') }}</h5>
            <div v-if="user.coach_avis && user.coach_avis.length > 0" class="avisCoach">
                <v-carousel v-model="model" hide-delimiters height="250" :show-arrows="user.coach_avis.length == 1 ? false : true">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        fab
                        >
                        <v-icon text color="darkgrey">$prev</v-icon>
                    </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        fab
                        >
                        <v-icon text color="darkgrey">$next</v-icon>
                    </v-btn>
                    </template>
                    <v-carousel-item
                    v-for="(avis, i) in user.coach_avis"
                    :key="'avis_'+i"
                    >
                        <div style="height:100%;" class="d-flex flex-column justify-center">
                            <div class="d-flex justify-space-between flex-row align-center mb-3">
                                <div>
                                    <h5>{{ avis.operation }}</h5>
                                    <p class="grey--text mb-0">{{ avis.operationtype }}</p>
                                </div>
                                <div class="d-flex justify-end">
                                    <v-img v-for="(icon, index) in getIconArray(avis.note)" align="center" :key="'icon_'+index+'_'+avis.id" :src="publicPath+'img/'+icon" contain height="20" width="20"></v-img>
                                    
                                </div>
                            </div>
                            
                            <div class="lightgrey pa-6 avisText">
                                <span class="darkgrey--text">{{ avis.comment }}</span>
                                
                            </div>
                            <!-- <v-img :src="media.url" v-if="media.object.mime.indexOf('image') !== -1" height="100%" contain style="z-index:10"></v-img>
                            <video controls height="100%" v-else>
                                <source :src="media.url" :type="media.object.mime" />
                            </video>
                            <div class="black">
                                <span>{{ media.object.title }}</span>
                            </div> -->
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0 mt-3">
                {{$t('noOpinionAvailable')}}
            </v-alert>
        </v-card-text>
    </v-card>        
</template>
<script>
export default {
    name: "coachRatingOpinionComponent",
    props: ["user"],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            model:0
        }
    },
    methods: {
        getIconArray(note){
            const newNote = ""+note / 2;
            let arrayNote = newNote.split('.');
            let iconArray = [];
            for (let index = 0; index < arrayNote[0]; index++) {
                iconArray.push('star-full.png')
            }
            if(arrayNote[1]){
                iconArray.push('star-half.png')
            }
            if(iconArray.length < 5){ 
                let iconLength = iconArray.length;
                for (let indexEmpty = 0; indexEmpty <( 5 - iconLength); indexEmpty++) {
                    iconArray.push('star.png')
                }
            }
            return iconArray
        }
    },
}
</script>
<style lang="scss">
    .avisCoach {
        .v-btn:not(.v-btn--round).v-size--default {
            min-width:36px !important;
            width:36px !important;
        }
        .v-window__prev, .v-window__next {
            background-color: rgb(240,240,240) !important;
        }
        .v-window-item {
            width:80%;
            margin:auto;
            // .v-responsive__content{
            //     text-align:center;
            //     background-color: #000;
            // }
            .black {
                position: absolute;
                bottom: 0;
                z-index:10;
                left:0;
                padding:8px 20px;
                background-color: #7a7879 !important;
            }
            .v-image__image{
                z-index:10;
            }
            
        }
        .avisText {
            border-radius: 8px;
        }
    }
</style>