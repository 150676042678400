<template>
    <v-card rounded="lg">
        <v-card-title>
            <h2 class="icon-title mb-0" style="width:100%; align-items:center;">
                {{ $t("notificationPreferences") }}
                <v-btn v-if="$func.hasRight('user/preferencemanagement') || this.$store.state.auth.user.id === user.id" class="square" depressed text @click="$emit('edit')">
                    <v-icon small color="primary">$pencil_d</v-icon>
                </v-btn>
            </h2>
        </v-card-title>
        <v-card-text>
            <div v-for="(notifs, key) in getNotificationsByCategories">
                <h5>{{ $t('notif_text_' + key) }} :</h5>
                <div class="py-3 d-table ">
                    <div v-for="(notif, notifKey) in notifs" class="mb-3">
                        <v-icon :color="notif.value ? 'success' : 'error'" small class="mr-2">{{ notif.value ? '$check_l' : '$close' }}</v-icon>
                        {{ $t(notif.text) }}
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "notificationPreferencesComponent",
    props: ["user"],
    data() {
        return {
        };
    },
    computed: {
        getNotificationsByCategories() {
            let notifsByCategories = {};
            for (const [key, value] of Object.entries(this.user.notification_preferences)) {
                if(!notifsByCategories[value['category']])
                    notifsByCategories[value['category']] = [];

                value['text'] = key;

                notifsByCategories[value['category']].push(value);
            }
            return notifsByCategories;
        }
    }
};
</script>

<style lang="scss">
</style>
