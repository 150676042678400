<template>
    <v-card rounded="lg" class="pa-3">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <div class="d-flex align-center">
                        <div class="coachProfile">
                            <v-avatar size="130">
                                <v-img :src="api+'/user/'+user.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true&ts='+rand" :key="avatarKey"></v-img>
                            </v-avatar>
                            <div class="coachGlobalRating" v-if="user.coach_avis && user.coach_avis.length > 0">
                                <div class="coachStarRating">
                                    <b class="rating">{{ globalNote }}</b>
                                    <v-icon large color="#ffc107">$star_s</v-icon>
                                </div>
                            </div>
                        </div>
                        <div class="ml-6">
                            <h5><b style="font-weight:800; font-size:24px;">{{ user.firstname }} {{ user.name }}</b></h5>
                            <p class="mb-0" v-if="user.coach_detail && user.coach_detail.catch_phrase">"{{ user.coach_detail.catch_phrase }}"</p>
                        </div>
                    </div>
                    <div>
                        <v-btn v-if="!isPreview" @click="getForm('/user/'+user.id+'/getCoachForm')" depressed small color="primary" style="position:absolute; top:20px; right:50px;">{{$t('modifyPublicProfile')}}</v-btn>
                        <v-btn v-if="!isPreview && ($func.hasRight('user/update') || this.$store.state.auth.user.id === user.id)" class="square" depressed style="position:absolute; top:15px; right:10px;" text @click="$emit('edit')">
                            <v-icon small color="primary">$pencil_d</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" v-if="user.coach_detail && user.coach_detail.skills && user.coach_detail.skills.length > 0">
                    <h5>{{ $t('skills') }}</h5>
                    <v-chip v-for="(skill, index) in user.coach_detail.skills" color="#efedff" :key="'skill_'+index" class="mr-2 mb-2">{{ skill }}</v-chip>
                </v-col>
                <v-col cols="12" v-if="user.coach_detail && user.coach_detail.sectors && user.coach_detail.sectors.length > 0">
                    <h5>{{ $t('predilection_sectors') }}</h5>
                    <v-tooltip bottom  v-for="(sector, index) in user.coach_detail.sectors" :key="'sector_'+index">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip color="#FFF" class="mr-2 mb-2 elevation-2" v-bind="attrs" v-on="on">
                                <v-icon>{{ sector.icon }}</v-icon>
                            </v-chip>
                        </template>
                        <span>{{$t(sector.text)}}</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="12" v-if="user.coach_detail && user.coach_detail.experiences && user.coach_detail.experiences.length > 0">
                    <h5>{{ $t('experiences') }}</h5>
                    <ul style="font-size:14px;">
                        <li v-for="(exp, index) in user.coach_detail.experiences">{{ exp }}</li>
                    </ul>
                </v-col>
            </v-row>
        </v-card-text>
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :type="'edit'"
            :form="form"
            :action="'SET_DATA'"
            />
    </v-card>
</template>
<script>
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins'
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "coachIdComponent",
    props: ["user", "rand", "isPreview"],
    mixins: [getForm],
    components: {
        FormDialogComponent
    },
    data() {
        return {
            api:process.env.VUE_APP_API_URL,
            form:null,
            avatarKey:0,
        }
    },
    watch: {
        user(){
            this.avatarKey++;
        }
    },
    methods: {
        customRefresh(){
            this.$emit('refreshUser');
         
        }
    },
    computed: {
        globalNote:function(){
            if(this.user.coach_avis && this.user.coach_avis.length > 0){
                let total = 0;
                this.user.coach_avis.forEach((avis) => {
                    total += parseInt(avis.note)
                })
                let newTotal = total / this.user.coach_avis.length
                let test = ""+newTotal
                if(test.length > 3)
                    newTotal = newTotal.toFixed(1)
                else
                    newTotal = newTotal
                return newTotal
            } else {
                return 0
            }
        }
    },
}
</script>
<style lang="scss">
.coachProfile{
    position:relative;
}
    .coachGlobalRating {
        position:absolute;
        bottom:0;
        right:0;
        .coachStarRating {
            position:relative;
            .rating {
                text-align: center;
                width: 100% !important;
                position:absolute;
                top:calc(50% + 2px);
                left:50%;
                z-index:15;
                transform:translate(-50%, -50%);
                font-size:11px;
                font-weight:800;
            }
        }
    }
    .shadow {
        -webkit-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0.4) !important;
        -moz-box-shadow: 2px 2px 6px 0px rgba(235,235,235,0.4) !important;
        box-shadow: 2px 2px 6px 0px rgba(235,235,235,0.4) !important;
    }
</style>