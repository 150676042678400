<template>
  <v-container fluid class="pa-6" :key="'user_'+keyUser">
    <!-- /ACTIONS GLOBALES -->
    <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />
    <v-row  v-if="user.is_presta && user.is_coach">
      <v-col cols="5">
        <coachIdComponent :user="user" @refreshUser="refreshUser" @edit="getForm(formUrl)" :rand="rand" :key="$store.state.auth.avatarKey"/>
      </v-col>
      <v-col cols="7">
        <coachRatingOpinionComponent :user="user" />
        <coachMediaComponent class="mt-6" :user="user" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12" lg="6" v-show="Object.keys(user).length > 0">
        <h2 >{{$t('identity')}}</h2>
        <idComponent :user="user" class="mb-5" ref="idComponent" @edit="getForm(formUrl)" :key="$store.state.auth.avatarKey" :rand="rand"/>
        <notificationPreferencesComponent v-if="Object.entries(user).length > 0 && packId !== 1" :user="user" class="mb-5" ref="notificationPreferencesComponent" @edit="getForm(formEditNotificationPreferencesUrl)" />
      </v-col>
      <v-col cols="12" v-show="Object.keys(apiError).length > 0">
         <v-btn color="error" dense depressed @click="$router.go(-1)" class="mb-2">
          Retour
        </v-btn>
         <v-alert
          text
          type="error"
          icon="$warning_s"
        >
          Une erreur est survenue : <br/>
          {{apiError.status}} - {{apiError.statusText}}
          <div class="ApiError" v-show="displayErrors == 'true'" v-html="apiError.data">
            
          </div>
        </v-alert>
       
      </v-col>
    </v-row>
    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :type="'edit'"
      :form="form"
      :action="'SET_DATA'"
    />
  </v-container>
</template>

<script>

const userSubActions = {
  buttonLoggedInAs: {
    type: "button",
    square: true,
    icon: "$signInAlt_l",
    color: "warning",
    action: "emit",
    emitAction: "loggedAs",
    apiUrl: "/user/get?id={{id}}",
    hide: false,
    rights: ["user/logas"],
    tooltip: "loggedastooltip"
  },
  button_unlock: {
    type: "button",
    square: true,
    icon: "$unlock_l",
    color: "success",
    action: 'confirm',
    confirm: {
      label: "userUnlockConfirmTitle",
      message: "userUnlockConfirmMessage",
      checkurl: ""
    },
    url: "/user/{{id}}/enable",
    item: {},
    refreshData: {
        apiUrl: "/user/get?id={{id}}",
        mutation: "user/setUserData"
    },
    hide: false,
    rights: ["user/advanced"],
    tooltip: "unlocktooltip"
  },
  button_lock: {
    type: "button",
    square: true,
    icon: "$lock_l",
    color: "error",
    action: 'confirm',
    confirm: {
      label: "userLockConfirmTitle",
      message: "userLockConfirmMessage",
      checkurl: ""
    },
    url: "/user/{{id}}/disable",
    item: {},
    refreshData: {
        apiUrl: "/user/get?id={{id}}",
        mutation: "user/setUserData"
    },
    hide: false,
    rights: ["user/advanced"],
    tooltip: "locktooltip"
  },
  menu_1: {
    type: "menu",
    title: "Actions",
    color: "primary",
    menus: [
      [
        { 
          title: "userChangePassword",
          action: "form",
          url: "/user/getFormNewPassword",
          formData: {
            apiUrl: "/user/get?id={{id}}",
            mutation: "user/setUserData"
          },
          item: {},
          rights: ["user/advanced"]
        },
        // { 
        //   title: "userResetPassword",
        //   action: "confirm",
        //   confirm: {
        //     label: "userResetPasswordConfirmTitle",
        //     message: "userResetPasswordConfirmMessage",
        //     checkurl: ""
        //   },
        //   url: "/user/sendChangePassword",
        //   item: {},
        //   rights: ["user/advanced"]
        // },
        { 
          title: "userResetSettings",
          action: "confirm",
          confirm: {
            label: "userResetSettingsConfirmTitle",
            message: "userResetSettingsConfirmMessage",
            checkurl: ""
          },
          url: "/user/{{id}}/resetParameters",
          item: {},
          rights: ["user/advanced"]
        },
      ],
      [
        {
            // action: "delete",
            // confirm: {
            //     label: "confirmDelete",
            //     message: "userDeleteConfirmMessage",
            //     checkurl: "\/user\/checkBeforeDelete"
            // },
            action: "form",
            //url: "/user/getFormNewPassword",
            formData: {
              apiUrl: "/user/get?id={{id}}",
              mutation: "user/setUserData"
            },
            item:{},
            url: "\/user\/checkBeforeDelete?id={{id}}",
            icon: "trashalt_d",
            title: "actionDelete",
            route: "/users",
        }
      ],
    ],
  },
};


import SuccessErrorAlertComponent from '@/components/alerts/SuccessErrorAlertComponent'
import idComponent from "@/modules/users/usersComponents/idComponent";
import coachIdComponent from '@/modules/users/usersComponents/coachIdComponent.vue';
import coachRatingOpinionComponent from '@/modules/users/usersComponents/coachRatingOpinionComponent.vue';
import coachMediaComponent from '@/modules/users/usersComponents/coachMediaComponent.vue';
import notificationPreferencesComponent from "@/modules/users/usersComponents/notificationPreferencesComponent";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins'
import refreshData from '@/mixins/mixins'
import GenericDataService from '@/services/GenericDataService'
import CoachIdComponent from '../usersComponents/coachIdComponent.vue';
import CoachRatingOpinionComponent from '../usersComponents/coachRatingOpinionComponent.vue';
export default {
  name: "UserDetail",
  components: {
    SuccessErrorAlertComponent,
    idComponent,
    notificationPreferencesComponent,
    FormDialogComponent,
    CoachIdComponent,
    CoachRatingOpinionComponent,
    coachMediaComponent
},
  mixins:[getForm, refreshData],
  data() {
    return {
      formUrl: "/user/getFormUser?id=" + this.$route.params.user_id,
      formEditNotificationPreferencesUrl: "/user/getFormNotificationPreferences?id=" + this.$route.params.user_id,
      form: null,
      apiUrl: "/user/get?id=" + this.$route.params.user_id,
      mutation: "user/setUserData",
      apiError:{},
      displayErrors: process.env.VUE_APP_DISPLAY_ERRORS,
      keyUser:0,
      rand:0
    };
  },
  created(){
      //this.apiError = {};
      this.refreshData()
      
  },
  updated(){
    
  },
  destroyed() {
    this.$store.dispatch('user/SET_USER_DATA', {});
    //this.$store.dispatch('base/SET_SUB_MENU', []);
    this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
    this.apiError = {};
  },
  
  watch: {
    $route(to, from) {
      this.formUrl = "/user/getFormUser?id=" + this.$route.params.user_id;
      this.formEditNotificationPreferencesUrl = "/user/getFormNotificationPreferences?id=" + this.$route.params.user_id;
      this.apiUrl = "/user/get?id=" + this.$route.params.user_id;
      this.refreshData()
    },
    apiError(newVal){
        if(Object.keys(newVal).length == 0){
            this.$store.dispatch('user/SET_USER_DATA', {})
        }
    },
    user(newVal){
      this.$store.dispatch('base/SET_HEADER_ACTIONS', this.subActions);
      this.$store.dispatch('auth/CHANGE_AVATAR_KEY')
      this.keyUser++
      this.rand = Date.now();
     
    }
  },
  methods: {
    refreshUser(){
      this.refreshData()
      
    },
  },
  computed: {
    packId(){
      if(this.$store.state.auth.currentUser.accountParameters.packId)
        return this.$store.state.auth.currentUser.accountParameters.packId
      else 
        return false
    },
    user: function () {
      return this.$store.state.user.user;
    },
    subActions: function () {
      let subActions = this._.cloneDeep(userSubActions)
      subActions.buttonLoggedInAs.apiUrl = subActions.buttonLoggedInAs.apiUrl.replace('{{id}}', this.user.id);
      subActions.buttonLoggedInAs.hide = (((this.$store.state.auth.loggedAs != null && this.$store.state.auth.loggedAs != false ) || this.$store.state.auth.currentUser.profile.id === this.user.id) || !parseInt(this.user.active));
      subActions.button_unlock.hide = parseInt(this.user.active);
      if(((this.$store.state.auth.loggedAs != null && this.$store.state.auth.loggedAs != false ) || this.$store.state.auth.currentUser.profile.id === this.user.id)){
        subActions.button_lock.hide = true;
      } else {
        subActions.button_lock.hide = !parseInt(this.user.active);
      }
      this.apiUrl = "/user/get?id=" + this.user.id;
      this.mutation = "user/setUserData";
      subActions.button_unlock.url = subActions.button_unlock.url.replace('{{id}}', this.user.id);
      subActions.button_unlock.refreshData.apiUrl = subActions.button_unlock.refreshData.apiUrl.replace('{{id}}', this.user.id);
      subActions.button_lock.url = subActions.button_lock.url.replace('{{id}}', this.user.id);
      subActions.button_lock.refreshData.apiUrl = subActions.button_lock.refreshData.apiUrl.replace('{{id}}', this.user.id);
      subActions.menu_1.menus[0][0].item.id = this.user.id;
      subActions.menu_1.menus[0][0].formData.apiUrl =  subActions.menu_1.menus[0][0].formData.apiUrl.replace('{{id}}', this.user.id);
      //subActions.menu_1.menus[0][1].item.id = this.user.id;
      subActions.menu_1.menus[0][1].url = subActions.menu_1.menus[0][1].url.replace('{{id}}', this.user.id);
      subActions.menu_1.menus[1][0].item.id = this.user.id;
      subActions.menu_1.menus[1][0].formData.apiUrl =  subActions.menu_1.menus[1][0].formData.apiUrl.replace('{{id}}', this.user.id);
      subActions.menu_1.menus[1][0].url =  subActions.menu_1.menus[1][0].url.replace('{{id}}', this.user.id);
      if(((this.$store.state.auth.loggedAs != null && this.$store.state.auth.loggedAs != false ) || this.$store.state.auth.currentUser.profile.id === this.user.id))
        subActions.menu_1.menus.splice(1, 1); // On tèj le bouton supprimer de son propre User

        // Permet de cacher le bouton menu action si il n'y a pas d'action dedans
        let count = 0;
        let hide = 0;
        subActions.menu_1.menus.forEach(menu => {
            menu.forEach(button => {
                count++;
                hide = (button.rights && !this.$func.hasRight(button.rights)) ? (hide + 1) : hide;
            });
        });

        if(count === hide)
            delete subActions.menu_1;
        // Permet de cacher le bouton menu action si il n'y a pas d'action dedans

      return subActions;
    },
  }
};
</script>
<style lang="scss">
.ApiError {
  word-break: break-all;
}
</style>
