<template>
    <v-card rounded="lg" class="pa-3">
        <v-card-text class="mediaCoach">
            <h5 class="text-center mb-4">{{ $t('PhotoAndVideoLibrary') }}</h5>
            <div v-if="user.coach_media && user.coach_media.length > 0">
                <v-carousel v-model="model" hide-delimiters  height="400" :show-arrows="user.coach_media.length == 1 ? false : true">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        color="lightgrey"
                        fab
                        >
                        <v-icon text color="darkgrey">$prev</v-icon>
                    </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        color="grey"
                        fab
                        >
                        <v-icon text color="darkgrey">$next</v-icon>
                    </v-btn>
                    </template>
                    <v-carousel-item
                    v-for="(media, i) in user.coach_media"
                    :key="'media_'+i"
                    >
                        <div style="height:100%;">
                            <v-img :src="media.url" v-if="media.object.mime.indexOf('image') !== -1" height="100%" contain style="z-index:10"></v-img>
                            <video controls height="100%" v-else>
                                <source :src="media.url" :type="media.object.mime" />
                            </video>
                            <div class="black">
                                <span>{{ media.object.title }}</span>
                            </div>
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <v-alert v-else border='left' :icon="'$warning_s'" type="info" text class="mb-0 mt-3">
                {{$t('noMediaAvailable')}}
            </v-alert>
        </v-card-text>
    </v-card>        
</template>
<script>
export default {
    name: "coachMediaComponent",
    props: ["user"],
    data() {
        return {
            model:0
            //api:process.env.VUE_APP_API_URL,
        }
    },
}
</script>
<style lang="scss">
    .mediaCoach {
        .v-btn:not(.v-btn--round).v-size--default {
            min-width:36px !important;
            width:36px !important;
        }
        .v-window__prev, .v-window__next {
            background-color: rgb(240,240,240) !important;
        }
        .v-window-item {
            width:80%;
            margin:auto;
            .v-responsive__content{
                text-align:center;
                background-color: #000;
            }
            .black {
                position: absolute;
                bottom: 0;
                z-index:10;
                left:0;
                padding:8px 20px;
                background-color: #7a7879 !important;
            }
            .v-image__image{
                z-index:10;
            }
            
        }
    }
</style>